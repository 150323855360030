import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route } from "react-router-dom";
import Home from "./views/Home/Home";
import Skills from "./views/Skills/Skills";
import Me from "./views/Me/Me";

function App() {
  return (
    <div style={{ fontFamily: '"Roboto Slab", serif' }} className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/skills" element={<Skills />} />{" "}
        <Route path="/me" element={<Me />} />{" "}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
