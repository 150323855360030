import "./Me.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GoMarkGithub } from "react-icons/go";

function Me() {
  return (
    <div className="App">
      <header className="App-header">
        <Container>
          <Row></Row>
          <Row>
            <Col className="me.container">
              <img
                src="/images/Me.jpg"
                alt="me"
                className="animate__animated animate__flip image_me"
              />
            </Col>
            <Col className="me.image">
              <div className="animate__animated animate__fadeIn skill_list">
                <h1>Me</h1>
                <p>
                  My name is Petja Pohtamo. <br />I am a sales, management and
                  customer service professional.
                  <br /> As a software developer, I am still a beginner, but I
                  am really enthusiastic, motivated, and quick to learn new
                  things.
                </p>
                <div>
                  <a
                    href="https://github.com/Petja85"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="github_package github_icon_wrapper">
                      <GoMarkGithub className="github_icon" />
                      <span className="github_text"> My GitHub</span>
                    </div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </div>
  );
}

export default Me;
