import SkillItem from "../../components/SkillItem/SkillItem";
import WorkItem from "../../components/WorkItem/WorkItem";
import Container from "react-bootstrap/Container";
import "./Skills.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GoChecklist } from "react-icons/go";

const workList = [
  {
    title: "DNA-Kauppa",
    role: "Myymäläpäällikkö",
    description:
      "Vastasin myymälän toiminnasta ja myymälän tavoitteiden toteutuksesta.",
    startingDate: "22.9.2009",
    endDate: "1.11.2021",
  },
  {
    title: "2",
    role: "Myymäläpäällikkö",
    description:
      "Vastasin myymälän toiminnasta ja myymälän tavoitteiden toteutuksesta.",
    startingDate: "22.9.2009",
    endDate: "1.11.2021",
  },
  {
    title: "3",
    role: "Myymäläpäällikkö",
    description:
      "Vastasin myymälän toiminnasta ja myymälän tavoitteiden toteutuksesta.",
    startingDate: "22.9.2009",
    endDate: "1.11.2021",
  },
];
const skillList = [
  "Html/Css and Visual studio code basics.",
  "JavaScript basics",
  "React, Angular, Node.JS",
  "Sql basics",
  "Python basics",
  "Customer service- and salesjob",
  "Management",
  "Planning and managing events and projects",
  "O365 services basics",
  "WordPress + Woocommerce",
];

function Skills() {
  return (
    <div className="App">
      <div className="App-header">
        <Container className="animate__animated animate__fadeIn">
          <Row>
            {" "}
            <div>
              <a href="/files/petja_pohtamo_cv.pdf" target="_blank">
                <div className="cv_package cv_wrapper">
                  <GoChecklist className="cv_icon" />
                  <span className="cv_text"> My CV</span>
                </div>
              </a>
            </div>
          </Row>
          <Row>
            <Col className="skillcol">
              <div className="work_box">
                <h1>Work Experience</h1>
              </div>
              <div className="work_item">
                {workList.map((item) => (
                  <WorkItem
                    title={item.title}
                    role={item.role}
                    description={item.description}
                    startingDate={item.startingDate}
                    endDate={item.endDate}
                  />
                ))}
              </div>
            </Col>
            <Col className="skillcol">
              <div className="sset_box">
                <h1>Skillset</h1>{" "}
              </div>
              <div className="skill_list">
                {skillList.map((skill) => (
                  <SkillItem skill={skill} />
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default Skills;
