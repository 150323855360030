import "./MenuItem.css";

function MenuItem({ foodname, price, allergies }) {
  return (
    <div className="Menu_Item">
      <h3>{foodname}</h3>
      <h4>{allergies}</h4>
      <h5>{price + "€"}</h5>
    </div>
  );
}

export default MenuItem;
