import "./WorkItem.css";

/* {
  options shift a
  title: "2",
  role: "Myymäläpäällikkö",
  description:
    "Vastasin myymälän toiminnasta ja myymälän tavoitteiden toteutuksesta.",
  startingDate: "22.9.2009",
  endDate: "1.11.2021",
}, */
function WorkItem({ title, role, description, startingDate, endDate }) {
  return (
    <div className="work_wrapper">
      <div className="title">{title}</div>
      <div className="role">{role}</div>
      <div className="description">{description}</div>
      <div className="duration">{`${startingDate} - ${endDate}`}</div>
    </div>
  );
}

export default WorkItem;
