import { Button } from "react-bootstrap";
import "./Metoo.css";
import { useState } from "react";

function Metoo() {
  const [teksti, setTeksti] = useState("Paina");
  const handleClick = () => {
    setTeksti("Press");
  };
  return (
    <div className="Me_Too">
      <p>Hei Tervetuloa</p>
      <Button onClick={handleClick}>{teksti}</Button>
    </div>
  );
}

export default Metoo;
