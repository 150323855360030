import "./Footer.css";

function Footer() {
  return (
    <footer className="footer_wrapper">
      <div className="footer">
        <span className="footer_text">Copyright Petja ©2023</span>
      </div>
    </footer>
  );
}

export default Footer;
