import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { WindupChildren } from "windups";
import Spinner from "react-bootstrap/Spinner";
import "./Home.css";
import Metoo from "../../components/Metoo/Metoo";
import MenuList from "../../components/MenuList/MenuList";

function MyWindup({ teksti }) {
  return <WindupChildren>{teksti}</WindupChildren>;
}
function Home() {
  const [activity, setActivity] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getActivity = async () => {
    setIsLoading(true);
    const response = await fetch("https://www.boredapi.com/api/activity");
    const jsonData = await response.json();
    setIsLoading(false);
    console.log(jsonData);
    setActivity(jsonData.activity);
  };

  useEffect(() => {
    const getStartingData = async () => {
      await getActivity();
    };
    getStartingData();
  }, []);

  const handleClick = async () => {
    await getActivity();
  };

  return (
    <div className="homebg">
      <h2 className="animate__animated animate__fadeIn">
        Are you bored? <br />
        Press fetch to get something to do!
      </h2>
      <h3>{isLoading ? <Spinner /> : <MyWindup teksti={activity} />}</h3>
      <Button onClick={handleClick}>Fetch</Button>
      <Metoo />
      <div>
        <MenuList />
      </div>
    </div>
  );
}

export default Home;

/*  <MyWindup teksti="Hyppivä teksti" />
      <p>{text}</p>
      <Button
        text={`Nappia Painettu: ${count} kpl`}
        onClick={handleClick}
        message={message}
      />
      <div>{myElement}</div> */
