import "./MenuList.css";
import MenuItem from "../Menuitem/MenuItem";

const Menu1 = [
  {
    foodname: "Threestar menu",
    price: 40,
    allergies: "includes eggs",
  },
  {
    foodname: "Twostar menu",
    price: 50,
    allergies: "includes nuts",
  },
  {
    foodname: "1star menu",
    price: 80,
    allergies: "includes eggs, milk and peanuts",
  },
];
function MenuList() {
  return (
    <div className="Menu_List">
      {Menu1.map((menuitem) => (
        <MenuItem
          foodname={menuitem.foodname}
          price={menuitem.price}
          allergies={menuitem.allergies}
        />
      ))}
    </div>
  );
}

export default MenuList;
