import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { BiHome, BiIdCard, BiBriefcase } from "react-icons/bi";

function MyNavbar() {
  return (
    <Navbar expand="lg">
      <Container className="nav__navbar">
        <Navbar.Brand id="logo">
          <Link to="/">Petja</Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="home_box">
            <Link to="/">
              <BiHome className="icon" />
              <div className="homediv">Home</div>
            </Link>
          </Nav>
          <Nav className="me_box">
            <Link to="/me">
              <BiIdCard className="icon" />
              <div className="homediv">Me</div>
            </Link>
          </Nav>
          <Nav className="skills_box">
            <Link to="/skills">
              <BiBriefcase className="icon" />
              <div className="homediv">Skills</div>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;
